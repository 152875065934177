<template>
  <div class="profile__wrapper">
    <template v-if="structure">
      <div
        v-for="subgroup in structure"
        :key="subgroup.uuid"
        :style="{ order: subgroup.order_position }"
      >
        <h4 class="title-h4 profile__title" v-if="!!subgroup.title">
          {{ subgroup.title }}
        </h4>
        <div
          v-for="group in subgroup.subgroups"
          :key="group.uuid"
          class="report__block"
          :class="{ 'report__sub-block': !!group.level }"
          :style="{ order: group.order_position }"
        >
          <h5 class="title-h5 profile__title" v-if="!!group.title">
            {{ group.title }}
          </h5>

          <div class="report__grid">
            <div
              v-for="item in group.criterias"
              :key="item.uuid"
              :id="item.uuid"
              :class="[`${item.classnames}`]"
              :style="{ order: item.order_position }"
            >
              <template v-if="item.type === 'select'">
                <SelectField
                  v-model="item.value"
                  :uuid="item.uuid"
                  :items="item.options"
                  titleKey="title"
                  valueToReturn="object"
                  :title="item.title"
                  is-disabled
                  placeholder=""
                  comment-to="report"
                  is-commentable
                  isObjectType
                />
              </template>
              <template v-else>
                <text-field
                  :key="item.uuid"
                  :uuid="item.uuid"
                  v-model="item.value"
                  placeholder="-"
                  :title="item.title"
                  :value-mask="item.mask"
                  :bold-report="false"
                  :no-underline="!!item.sum_of.length"
                  is-inactive
                  comment-to="report"
                  is-commentable
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <LoaderContent />
    </template>
    <div class="no-print">
      <div class="control__btn-wrapper">
        <Button icon-name="print" isRightIcon @click.native="print">
          {{ $t('buttons.print') }}
        </Button>
        <Button @click.native="downloadPDF" :disabled="!isAchive">
          {{ $t('buttons.download_pdf') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getTourOperatorReportStructure } from '@/api/services/tour_operator';
import LoaderContent from '@/elements/LoaderContent.vue';
import SelectField from '@/elements/SelectField.vue';

export default {
  components: { LoaderContent, SelectField },

  data() {
    return {
      structure: null,
    };
  },

  async created() {
    await this.reportStrucure();
    await this.$store.dispatch('getReportsData', {
      report_uuid: this.$route.params.id,
    });
    await this.$store.dispatch('getReportsComments', this.$route.params.id);
    await this.setReportData();
  },

  computed: {
    ...mapGetters(['getReportsData']),

    reportsDataObj() {
      let obj = {};
      if (!!this.getReportsData) {
        this.getReportsData?.criteria.map((el) => {
          obj[el.criterion_uuid] = {
            value: el.value,
            uuid: el.uuid,
          };
        });
      }
      return obj;
    },

    isAchive() {
      return !!this.getReportsData?.archive;
    },
  },

  methods: {
    async reportStrucure() {
      let data = { lang: 'uk', uuid: this.$route.params.id };
      let res;
      try {
        res = await getTourOperatorReportStructure(data);
        if (res.status === 200) {
          this.structure = res.data.data.structure;
        }
      } catch (e) {
        console.log('>>> reportStrucure error', e);
      }
    },

    setReportData() {
      this.structure?.map((g) =>
        g?.subgroups?.map((s) =>
          s.criterias?.map((c) => {
            if (c.uuid in this.reportsDataObj) {
              if (c.type === 'select') {
                let selected = this.reportsDataObj[c.uuid].value.map((el) => {
                  return c.options.find(
                    (s) => s.uuid === el.criterion_select_option_uuid
                  );
                });
                c.value = selected;
              } else {
                c.value = this.reportsDataObj[c.uuid]?.value;
              }
            }
          })
        )
      );
    },

    downloadPDF() {
      this.$store.dispatch(
        'downloadFile',
        this.getReportsData.archive.original_url
      );
    },

    print() {
      window.print();
    },
  },
};
</script>
